import React from "react";
import { FaRegComment, FaRegHeart, FaHeart } from "react-icons/fa";
import { LuUpload } from "react-icons/lu";
import { useNavigate, Link } from "react-router-dom";
import "./Post.scss";
import { placeholder } from "../../constants/placeholder";
import { toast } from "react-toastify";
import MediaGallery from "../MediaGallery/MediaGallery";
import { shortenStory } from "../../utils/resolve";

const sharePost = (id) => {
  if (navigator.share) {
    navigator
      .share({
        title: "Share Post",
        text: "Check out this post",
        url: `${window.location.origin}/user/feed/post/${id}`,
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          toast.error(`Error: ${error}`, {
            toastId: "share-post-error",
          });
        }
      });
  } else {
    toast.info("Sharing is not supported in this browser.", {
      toastId: "share-not-supported",
    });
  }
};

const Post = ({ post, onLike }) => {
  const navigate = useNavigate();

  if (!post || !post.user || !post.user.name) {
    return null;
  }

  const {
    _id,
    user,
    formattedDate,
    body,
    images,
    videos,
    commentCount,
    likeCount,
  } = post;
  const { first: firstName } = user.name;
  const userImg =
    user?.image?.profile ?? placeholder(40, 40, "png", firstName[0]);

  const isLengthyPost = post.length > 100;
  const username = user.username;
  const profileUrl = `/user/public-profile/${user._id}`;
  const mediaExists = images.length !== 0 || videos.length !== 0;

  return (
    <section className="individual-post-container" key={_id}>
      <div className="individual-post-profile-picture">
        <p className="individual-post-profile-picture-container">
          <img
            onClick={() => navigate(profileUrl)}
            src={userImg}
            alt="profile"
          />
        </p>
      </div>
      <div className="individual-post-texts-container">
        <div className="individual-post-container-name-username-date">
          <Link className="individual-post-container-name" to={profileUrl}>
            {firstName}
          </Link>
          <Link className="individual-post-container-username" to={profileUrl}>
            @{username}
          </Link>
          <span className="individual-post-container-date">
            {formattedDate}
          </span>
        </div>
        <div className="individual-post-container-post-text">
          <div className="post-container">
            <p className="post-body">{shortenStory(body)}</p>
            {isLengthyPost && (
              <span className="post-read-more">
                <Link
                  to={`/user/feed/post/${_id}`}
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  Read more
                </Link>
              </span>
            )}
            {mediaExists && (
              <div className="post-media-container">
                <MediaGallery images={images} videos={videos} />
              </div>
            )}
          </div>
        </div>
        <div className="individual-post-container-like-comment">
          <div onClick={() => navigate(`/user/feed/post/${_id}`)}>
            <span>
              <FaRegComment />
            </span>
            <span>{commentCount ?? 0}</span>
          </div>
          <div onClick={() => onLike(_id)}>
            <span>
              {post.userLikes ? (
                <FaHeart color="red" />
              ) : (
                <FaRegHeart color="grey" />
              )}
            </span>
            <span>{likeCount ?? 0}</span>
          </div>
          <div onClick={() => sharePost(_id)}>
            <span>
              <LuUpload />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Post;
