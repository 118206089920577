export const formatDateOfBirth = (dob) => {
  const date = new Date(dob);
  const day = date.getDay();
  const month = date.toLocaleString("default", { month: "long" });
  return `${day} ${month}`;
};

export const checkStrongPassword = (password) => {
  const strongPassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  return strongPassword.test(password);
};

export const convertToShortDate = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();
  return `${month} ${day}`;
};

export const featuredImageFromPost = (post) => {
  if (post.featuredImage) {
    return post.featuredImage;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(post.body, "text/html");
  const firstImage = doc.querySelector("img");

  return firstImage ? firstImage.src : null;
};

export const shortenStory = (story) => {
  return story.length > 100 ? story.slice(0, 100) + "..." : story;
};

export const getFullYear = (date) => {
  return new Date(date).getFullYear();
};

export const pluralize = (word, count) => {
  return count === 1 ? word : `${word}s`;
};

const units = [
  { name: "year", seconds: 60 * 60 * 24 * 365 },
  { name: "month", seconds: 60 * 60 * 24 * 30 },
  { name: "week", seconds: 60 * 60 * 24 * 7 },
  { name: "day", seconds: 60 * 60 * 24 },
  { name: "hour", seconds: 60 * 60 },
  { name: "minute", seconds: 60 },
];

export const resolveNotificationDateTime = (date) => {
  const now = new Date();
  const providedDate = new Date(date);
  const diffInSeconds = Math.floor((now - providedDate) / 1000);

  if (diffInSeconds < 60) {
    return "Just now";
  }

  for (let unit of units) {
    const diffInUnits = Math.floor(diffInSeconds / unit.seconds);
    if (diffInUnits >= 1) {
      return `${diffInUnits} ${pluralize(unit.name, diffInUnits)} ago`;
    }
  }
};

export const stripHTMLTags = (html) => {
  if (!html) {
    return "";
  }
  return html.replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, " ");
};
