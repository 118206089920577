import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";

export const PublicProfileContext = createContext();

export const usePublicProfile = () => {
  return useContext(PublicProfileContext);
};

export const PublicProfileProvider = ({ children }) => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const accessToken = useBackendStore((store) => store.accessToken);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProfiles = async (search) => {
      try {
        const params = search ? { search } : {};
        const response = await axios.get(`${API_URL}/users/`, {
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setProfiles(response.data ?? []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [API_URL, accessToken, refresh]);

  return (
    <PublicProfileContext.Provider
      value={{ profiles, loading, error, setRefresh }}
    >
      {children}
    </PublicProfileContext.Provider>
  );
};
