import "./UserPageNav.scss";
import { GoHome } from "react-icons/go";
import { FaRegCircleUser } from "react-icons/fa6";
import { LiaTrophySolid } from "react-icons/lia";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoExitOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../logOut/Logout";
import { useEffect, useState } from "react";
import { useNotification } from "../../contexts/notificationsContext";

const UserPageNav = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { notifications } = useNotification;
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    if (notifications?.length > 0) {
    }
  }, [notifications]);

  return (
    <section className="user-page-nav">
      <div className="user-page-nav-div">
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <Link to="/user/home">
              <div className="user-page-nav-icon-div">
                <span className="user-page-nav-icon">
                  <GoHome />
                </span>
                <span className="user-page-nav-icon-text">Home</span>
              </div>
            </Link>
          </div>
        </section>
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <Link to="/user/profile">
              <div className="user-page-nav-icon-div">
                <span>
                  <FaRegCircleUser className="user-page-nav-icon" />
                </span>
                <span className="user-page-nav-icon-text">Profile</span>
              </div>
            </Link>
          </div>
        </section>

        <section
          className="user-page-nav-div-section"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="user-page-nav-div-section-div">
            <Link to="/user/feed/stories">
              <div className="user-page-nav-icon-div">
                <span>
                  <LiaTrophySolid className="user-page-nav-icon" />
                </span>
                <span className="user-page-nav-icon-text">
                  My Warriors Story
                </span>
              </div>
              {showDropdown && (
                <div className="dropdown-menu">
                  <button onClick={() => navigate("/user/create-story")}>
                    Go to Story
                  </button>
                  <button onClick={() => navigate("/user/create-post")}>
                    Go to Post
                  </button>
                </div>
              )}
            </Link>
          </div>
        </section>
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <Link to="/user/chat">
              <div className="user-page-nav-icon-div">
                <span>
                  <MdOutlineMailOutline className="user-page-nav-icon" />
                </span>
                <span className="user-page-nav-icon-text">Messages</span>
              </div>
            </Link>
          </div>
        </section>
        <section className="user-page-nav-div-section">
          <div className="user-page-nav-div-section-div">
            <button onClick={toggle}>
              <span>
                <IoExitOutline className="user-page-nav-icon" />
              </span>
              <span>Log Out</span>
            </button>
          </div>
        </section>
        <section className="user-page-nav-div-section">
          <section>{isOpen && <Logout toggleLogout={toggle} />}</section>
        </section>
      </div>
    </section>
  );
};
export default UserPageNav;
