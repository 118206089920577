import "./PostFeed.scss";
import { useEffect, useState, useRef, useCallback } from "react";
import usePost from "../../Hooks/usePost";
import Post from "../Post/Post";
import usePostStore from "../../store/posts-store";
import { convertToShortDate } from "../../utils/resolve";
import LogoSpinner from "../LogoSpinner/LogoSpinner";

const PostFeed = ({ userId }) => {
  const { getPosts } = usePost();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [openedPostId, setOpenedPostId] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const observer = useRef();
  const lastCreatedAtRef = useRef(null);
  const posts = usePostStore((store) => store.posts);
  const likePost = usePostStore((store) => store.likePost);
  const setPosts = usePostStore((store) => store.setPosts);
  const commentPost = usePostStore((store) => store.commentPost);

  const handlePostOpen = (postId) => {
    setOpenedPostId(postId);
  };

  const handlePostClose = () => {
    setOpenedPostId(null);
  };

  const observerTarget = useCallback(
    (node) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !loading) {
            setTriggerFetch((prev) => !prev);
          }
        },
        { threshold: 1 }
      );
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const fetchPosts = async () => {
      if (!hasMore) return;
      try {
        setLoading(true);
        const result = await getPosts(lastCreatedAtRef.current, userId);
        if (result.length > 0) {
          lastCreatedAtRef.current = result[result.length - 1].createdAt;
          setPosts(result);
        } else {
          observer.current && observer.current.disconnect();
          setHasMore(false);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [getPosts, hasMore, userId, setPosts, triggerFetch]);

  const handleComment = async (postId, comment) => {
    try {
      await commentPost(postId, comment);
    } catch (error) {
      setError(error);
    }
  };

  const stripHTMLTags = (str) => {
    return str.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "");
  };

  const formatDate = (dateString) => {
    return convertToShortDate(dateString);
  };

  const cleanedResponse = posts
    ? posts
        .map((item) => ({
          ...item,
          body: stripHTMLTags(item.body),
          featuredImage: item.images,
          formattedDate: formatDate(item.createdAt),
        }))
        .filter((item) => !userId || item.user._id === userId)
    : [];

  if (error) {
    return (
      <section className="postfeed-section-container">
        <div className="postfeed-container">
          <p>Error: {error.message}</p>
        </div>
      </section>
    );
  }

  return (
    <section className="postfeed-section-container">
      <div className="postfeed-container">
        {!loading && cleanedResponse.length === 0 ? (
          <p>No post created yet..</p>
        ) : (
          cleanedResponse.map((details, index) => (
            <div className="postfeed-div" key={index}>
              <Post
                post={details}
                onLike={likePost}
                onComment={handleComment}
                isOpen={openedPostId === details._id}
                onOpen={() => handlePostOpen(details._id)}
                onClose={handlePostClose}
              />
              <div className="postfeed-div-live" />
            </div>
          ))
        )}

        <div ref={observerTarget} />
        {loading && (
          <section className="postfeed-section-container">
            <div className="postfeed-container">
              <LogoSpinner className="loading-icon" />
            </div>
          </section>
        )}
      </div>
    </section>
  );
};

export default PostFeed;
