import "./UserSearchPage.scss";
import React, { useEffect, useState } from "react";
import { placeholder } from "../../constants/placeholder";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import useProfile from "../../Hooks/useProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const matchesSearchTerm = (value, searchTerm) => {
  if (!searchTerm) return false;
  return value?.toLowerCase().includes(searchTerm.toLowerCase());
};

const profileMatchesSearch = (profile, search) => {
  if (!search) return false;
  return (
    matchesSearchTerm(profile.name?.first, search) ||
    matchesSearchTerm(profile.name?.last, search) ||
    matchesSearchTerm(profile.username, search)
  );
};

const Profile = ({ profile, onClick }) => {
  const { _id, image, name, username } = profile;
  const profileImage =
    image?.profile || placeholder(40, 40, "png", name?.first[0]);
  const profileName = name?.first;

  return (
    <div className="search-page-content" key={_id}>
      <div className="search-user-profile-img-div" onClick={onClick}>
        <img
          src={profileImage}
          alt={profileName}
          className="search-user-profile-img"
        />
      </div>
      <div className="search-page-items">
        <h4>{profileName}</h4>
        <p>@{username}</p>
      </div>
    </div>
  );
};

const UserSearchPage = () => {
  const [profiles, setProfiles] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { searchProfiles } = useProfile();
  const navigate = useNavigate();

  const navigateToProfile = (profile) => {
    navigate(`/user/public-profile/${profile._id}`);
  };

  useEffect(() => {
    if (!search) {
      return;
    }
    const fetchProfiles = async () => {
      try {
        setLoading(true);
        const profiles = await searchProfiles(search);
        setProfiles(profiles);
      } catch (error) {
        toast.error("Oops! Something went wrong.", { toastId: "search-error" });
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [search, searchProfiles]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const getFilteredProfiles = () => {
    return profiles.filter((profile) => profileMatchesSearch(profile, search));
  };

  const filteredProfiles = getFilteredProfiles();

  return (
    <div className="search-page-section">
      <div className="search-page-input-field">
        <input
          type="text"
          placeholder="Search for user"
          value={search}
          onChange={handleSearchChange}
        />
      </div>
      <div className="NotificationsAndSearch">
        {!loading ? (
          filteredProfiles.length >= 0 ? (
            filteredProfiles.map((profile) => (
              <Profile
                profile={profile}
                key={profile?._id}
                onClick={() => navigateToProfile(profile)}
              />
            ))
          ) : (
            <p>{search ? "No profiles found" : "Start typing to search"}</p>
          )
        ) : (
          <section className="postfeed-section-container">
            <div className="postfeed-container">
              <LogoSpinner className="loading-icon" />
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default UserSearchPage;
