import React, { useEffect } from "react";
import "./styles.scss";
import line from "../../assets/Img/resized.jpg";
import useBackendStore from "../../store/auth-store";
import { useNavigate } from "react-router-dom";
import OTPForm from "../OtpForm/OTPForm";

function EmailPage() {
  const accessToken = useBackendStore((state) => state.accessToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      navigate("/register");
    }
  }, [accessToken, navigate]);

  return (
    <div className="emailpage">
      <div className="title-emaili">
        <p>
          <span> Enter the code below to verify your account.</span>
        </p>
      </div>
      <div className="div-image">
        <img src={line} alt="line" />
      </div>
      <div className="body">
        <OTPForm accessToken={accessToken} />
      </div>
    </div>
  );
}

export default EmailPage;
