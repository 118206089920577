import "./profilepage.scss";
import { useRef, useState, useEffect } from "react";
import Logout from "../logOut/Logout";
import useUpdateProfile from "../../Hooks/useUpdateProfile";
import useProfile from "../../Hooks/useProfile";
import { MdOutlineCameraAlt } from "react-icons/md";
import { toast } from "react-toastify";
import { placeholder } from "../../constants/placeholder";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const { getProfile } = useProfile();
  const [loading, setLoading] = useState(true);
  const [showLogout, setShowLogout] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [about, setAbout] = useState("");
  const [profession, setProfession] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const coverImageInput = useRef();
  const profileImageInput = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile();
      if (profile) {
        setFirstName(profile.user?.name?.first ?? "");
        setLastName(profile.user?.name?.last ?? "");
        setDob(profile.user?.dob ?? "");
        setAbout(profile.user?.about ?? "");
        setProfession(profile.user?.profession ?? "");
        setProfileImageUrl(
          profile.user?.image?.profile ??
            placeholder(40, 40, "png", profile.user?.name?.first[0])
        );
        setCoverImageUrl(
          profile.user?.image?.cover ??
            placeholder(800, 200, "png", "background")
        );
      }
      setLoading(false);
    };

    fetchProfile();
  }, [getProfile]);

  const { updateProfile, updateCoverImage, updateProfileImage } =
    useUpdateProfile();

  const handleImageUpdate = (image, updateImageFunction) => {
    const imagePromise = updateImageFunction(image);
    return imagePromise.then((imageUrl) => {
      return imageUrl;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedFields = {};

    if (firstName) updatedFields["name.first"] = firstName;
    if (lastName) updatedFields["name.last"] = lastName;
    if (dob) updatedFields.dob = dob;
    if (about) updatedFields.about = about;
    if (profession) updatedFields.profession = profession;

    const imageUpdatePromises = [];
    if (profileImage) {
      imageUpdatePromises.push(
        handleImageUpdate(profileImage, updateProfileImage)
      );
    }
    if (coverImage) {
      imageUpdatePromises.push(handleImageUpdate(coverImage, updateCoverImage));
    }

    const updatePromise = Promise.all(imageUpdatePromises).then((urls) => {
      if (urls[0]) updatedFields["image.profile"] = urls[0];
      if (urls[1]) updatedFields["image.cover"] = urls[1];
      return updateProfile(updatedFields);
    });

    toast
      .promise(
        updatePromise,
        {
          pending: "Updating profile...",
          success: "Profile updated successfully",
          error: "Failed to update profile",
        },
        {
          toastId: "update-profile",
        }
      )
      .then(() => {
        navigate("/user/profile");
      });
  };

  const handleProfileImageUpload = (event) => {
    if (event.target.files[0].size > 512000) {
      toast.error("File size should be less than 500KB", {
        toastId: "file-size",
      });
      profileImageInput.current.value = "";
      setProfileImage(null);
    } else {
      setProfileImage(event.target.files[0]);
      setProfileImageUrl(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleCoverImageUpload = (event) => {
    if (event.target.files[0].size > 512000) {
      toast.error("File size should be less than 500KB", {
        toastId: "file-size",
      });
      coverImageInput.current.value = "";
      setCoverImage(null);
    } else {
      setCoverImage(event.target.files[0]);
      setCoverImageUrl(URL.createObjectURL(event.target.files[0]));
    }
  };

  const toggleLogout = () => {
    setShowLogout((prev) => !prev);
  };

  return (
    <div>
      <div className="profile-page-edit">
        {loading ? (
          <section className="storyfeed-section-container">
            <div className="storyfeed-container">
              <LogoSpinner className="loading-icon" />
            </div>
          </section>
        ) : (
          <div className="profile-page-edit-container">
            <section className="profile-page-edit-inputs-section">
              <div className="profile-page-edit-inputs-section-div">
                <form style={{ position: "relative" }}>
                  <div className="curved-div">
                    <svg
                      width="100%"
                      height="300px"
                      viewBox="0 0 500 150"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0,100 C100,110 250,10 650,140 L700,00 L0,0 Z"
                        style={{ fill: "rgb(139, 1, 0)" }}
                      ></path>
                    </svg>
                  </div>
                  <div className="profile-page-edit-profile-picture-and-banner-edit">
                    <div className="profile-page-edit-background-image">
                      <div
                        className="profile-page-edit-background-image-div"
                        onClick={() => coverImageInput.current.click()}
                      >
                        <img src={coverImageUrl} alt={"backgroundimage"} />
                        <div className="profile-page-edit-background-image-camera-button">
                          <MdOutlineCameraAlt />
                        </div>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={coverImageInput}
                          accept="image/*"
                          onChange={handleCoverImageUpload}
                        />
                      </div>
                    </div>
                    <div className="profile-page-edit-profile-picture">
                      <div
                        className="profile-page-edit-profile-picture-div"
                        onClick={() => profileImageInput.current.click()}
                      >
                        <img src={profileImageUrl} alt={"profilepicture"} />
                        <div className="profile-page-edit-profile-picture-camera-button">
                          <MdOutlineCameraAlt />
                        </div>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={profileImageInput}
                          accept="image/*"
                          onChange={handleProfileImageUpload}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <form
                  className="profile-page-edit-inputs-form"
                  onSubmit={handleSubmit}
                >
                  <div>
                    <p>First Name</p>
                    <input
                      type={"text"}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>Last Name</p>
                    <input
                      type={"text"}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>Birthday</p>
                    <input
                      type={"date"}
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </div>
                  <div className="profile-page-edit-inputs-about-input-field">
                    <p>About</p>
                    <textarea
                      placeholder="Say something about yourself"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>Profession</p>
                    <input
                      type={"text"}
                      value={profession}
                      onChange={(e) => setProfession(e.target.value)}
                    />
                  </div>
                  <div className="profile-page-edit-save-button">
                    <button type="submit">Save Changes</button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
        {showLogout && (
          <div className="modal-overlay" onClick={toggleLogout}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <Logout toggleLogout={toggleLogout} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProfilePage;
