import { create } from "zustand";
import { persist } from "zustand/middleware";

const useBackendStore = create(
  persist(
    (set) => ({
      accessToken: null,
      user: {
        username: null,
        name: {
          first: null,
          last: null,
        },
        email: null,
        dob: null,
        image: {
          profile: null,
          cover: null,
        },
        about: null,
        profession: null,
      },

      setAccessToken: (token) =>
        set((state) => ({
          accessToken: token,
        })),

      setEmail: (email) =>
        set((state) => ({
          user: {
            email: email,
          },
        })),
      setDob: (dob) =>
        set((state) => ({
          user: {
            dob: dob,
          },
        })),
      resetToken: () => set((state) => ({ accessToken: null })),
      setName: (name) =>
        set((state) => ({
          user: {
            fullName: name,
          },
        })),
      updateProfile: (profile) =>
        set((state) => ({
          user: Object.keys(profile).reduce(
            (acc, key) => {
              if (key in state.user) {
                acc[key] = profile[key];
              }
              return acc;
            },
            { ...state.user }
          ),
        })),
    }),

    {
      name: "useBackendStore",
    }
  )
);

export default useBackendStore;
