import React, { useEffect, useState } from "react";
import logo from "../navbar/Logo.svg";
import { useNavigate } from "react-router-dom";
import InputSearch from "../inputSearch/InputSearch";
import { tokenVerificationMessages } from "../../constants/messages";
import { useAuthContext } from "../../contexts/authContext";

const Navbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { tokenStatus } = useAuthContext();
  const isLoggedIn = tokenStatus === tokenVerificationMessages.OK;

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 699) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavigation = (path) => () => navigate(path);

  const renderMenuItems = () => (
    <ul className="navbar-ul">
      <li onClick={handleNavigation("/about")}>About Us</li>
      <li onClick={handleNavigation("/volunteer")}>Volunteer</li>
      <li onClick={handleNavigation("/login")}>Login</li>
      <li onClick={handleNavigation("/register")}>Register</li>
      <li>
        <button className="First-Donate-Now-btn">Donate Now</button>
      </li>
    </ul>
  );

  return (
    <nav>
      <div className="Navbar">
        <div className="Navbar-Left">
          <img
            src={logo}
            className="logo"
            alt="logo"
            onClick={handleNavigation("/")}
          />
          {!isLoggedIn && (
            <div className={`Navbar-List2 ${menuOpen ? "is-open" : ""}`}>
              {renderMenuItems()}
            </div>
          )}
          {isLoggedIn && <InputSearch />}
        </div>
        {!isLoggedIn && (
          <div className="Nav-hamburger" onClick={toggleMenu}>
            <span className="Hamburger">&#9776;</span>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
