import { create } from "zustand";
import axios from "axios";
import useBackendStore from "./auth-store";

const API_URL = process.env.REACT_APP_API_URL;

const accessToken = useBackendStore.getState().accessToken;
const useChatStore = create((set) => ({
  messages: [],
  addMessage: async (message) => {
    set((state) => ({
      messages: [...state.messages, message],
    }));
  },
  getMessages: async ({ from, to }) => {
    try {
      const response = await axios.get(`${API_URL}/chat/message`, {
        params: { from, to },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      set({
        messages: response.data.map((message) => ({
          ...message,
          direction: message.from === from ? "outgoing" : "incoming",
        })),
      });
    } catch (error) {
      throw new Error("Failed to fetch messages");
    }
  },
  eraseMessages: () => {
    set({ messages: [] });
  },
}));

export default useChatStore;
