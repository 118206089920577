import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import messaging from "../config/firebase";
import { getToken } from "firebase/messaging";
import useNotifications from "../Hooks/useNotifications";
import { useAuthContext } from "./authContext";
import { toast } from "react-toastify";
import { tokenVerificationMessages } from "../constants/messages";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [permitted, setPermitted] = useState(false);
  const [notificationsDisabled, setNotificationsDisabled] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const lastCreatedAtRef = useRef(null);
  const { setFCMToken, getUserNotifications } = useNotifications();
  const { tokenStatus } = useAuthContext();
  const isLoggedIn = tokenStatus === tokenVerificationMessages.OK;

  const handleFCMToken = useCallback(
    async (token) => {
      try {
        await setFCMToken(token);
      } catch (error) {
        toast.error("Oops! Something went wrong. Kindly contact support.", {
          toastId: "fcm-token-error",
        });
      }
    },
    [setFCMToken]
  );

  const loadMoreNotifications = useCallback(() => {
    if (isLoggedIn) {
      setLoading(true);
      getUserNotifications(lastCreatedAtRef.current)
        .then((notifications) => {
          if (notifications.length === 0) {
            setHasMore(false);
          } else {
            lastCreatedAtRef.current =
              notifications[notifications.length - 1].createdAt;
            setNotifications((prevNotifications) => [
              ...prevNotifications,
              ...notifications,
            ]);
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Oops! Something went wrong. Kindly contact support.", {
            toastId: "load-more-notifications-error",
          });
          setLoading(false);
        });
    }
  }, [getUserNotifications, isLoggedIn]);

  const requestPermission = useCallback(() => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        setPermitted(true);
      } else {
        setNotificationsDisabled(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setLoading(true);
      getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            handleFCMToken(currentToken);
          } else {
            !notificationsDisabled && requestPermission();
          }
        })
        .catch(() => {});

      getUserNotifications()
        .then((notifications) => {
          if (notifications.length === 0) {
            setHasMore(false);
          } else {
            lastCreatedAtRef.current =
              notifications[notifications.length - 1].createdAt;
            setNotifications(notifications);
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Oops! Something went wrong. Kindly contact support.", {
            toastId: "get-user-notifications-error",
          });
          setLoading(false);
        });
    }
  }, [
    handleFCMToken,
    notificationsDisabled,
    requestPermission,
    getUserNotifications,
    isLoggedIn,
  ]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        permitted,
        requestPermission,
        loadMoreNotifications,
        hasMore,
        loading,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
