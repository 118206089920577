import React from "react";
import one from "../assets//Img/New/EDO WOMEN.png";
import two from "../assets/Img/SphiderAssWebLogo (1).jpg";
import four from "../assets/Img/New/EDO 2.png";
import six from "../assets/Img/New/assisials icon.png";

export default function Logo() {
  return (
    <div className="Logo-Heading">
      <h4>Our Partners</h4>
      <div className="Logo-Img">
        <img src={one} alt="" className="Logo-image" width="100%" />
        <img
          src={two}
          alt=""
          className="Logo-image sphider-ass-web-logo"
          width="100%"
        />
        <img src={four} alt="" className="Logo-image" width="100%" />
        <img
          src={six}
          alt=""
          className="Logo-image the-assicials"
          width="100%"
        />
      </div>
    </div>
  );
}
