import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import io from "socket.io-client";
import useBackendStore from "../store/auth-store";
import {
  CHAT_UNREAD_EVENT,
  CONNECT_EVENT,
  ERROR_EVENT,
  NOTIFICATION_EVENT,
} from "../constants/socketEvents";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [unreadChats, setUnreadChats] = useState({});
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const socketRef = useRef(null);
  const accessToken = useBackendStore((state) => state.accessToken);

  const ENDPOINT = process.env.REACT_APP_API_URL;

  const resetUnreadNotifications = () => {
    setUnreadNotifications(0);
  };

  const resetUnreadChats = (id) => {
    if (id === unreadChats?.chatId) {
      console.log("Resetting unread chats");
      setUnreadChats({ chatId: id, unreadMessages: 0 });
    }
  };

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    setLoading(true);
    const socketInstance = io(ENDPOINT, {
      auth: { token: accessToken },
    });
    socketRef.current = socketInstance;
    socketInstance.on(CONNECT_EVENT, () => {
      setLoading(false);
      console.log("Connected to socket.io");
    });

    socketInstance.on(NOTIFICATION_EVENT, (data) => {
      setUnreadNotifications(data);
      console.log("Unread notifications", data);
    });

    socketInstance.on(CHAT_UNREAD_EVENT, (data) => {
      setUnreadChats(data);
      console.log("Unread chats", data);
    });

    socketInstance.on(ERROR_EVENT, (error) => {
      console.error("Error connecting to socket.io server", error);
    });

    return () => {
      socketInstance.disconnect();
    };
  }, [ENDPOINT, accessToken]);

  return (
    <SocketContext.Provider
      value={{
        socket: socketRef.current,
        socketLoading: loading,
        unreadChats,
        unreadNotifications,
        resetUnreadNotifications,
        resetUnreadChats,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
