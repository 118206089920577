import { create } from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";

export const useOTPFormStore = create(
  persist(
    (set, get) => ({
      code: "",
      error: null,
      disableResend: false,
      countdown: 60, // Add countdown state here
      setCode: (code) => set({ code }),
      setError: (error) => set({ error }),
      setDisableResend: (disableResend) => set({ disableResend }),
      setCountdown: (countdown) => set({ countdown }), // Add setCountdown function here

      sendOTP: async (accessToken) => {
        set({ disableResend: false });
        const setError = (error) => set({ error });
        setError(null);
        const apiUrl = process.env.REACT_APP_API_URL;

        if (!accessToken) {
          setError("Access token not found");
          return;
        }

        try {
          const res = await axios.get(`${apiUrl}/auth/verify-email`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = res.data;

          if (data.error) {
            setError(data.error);
          } else {
            set({ disableResend: true });
            set({ countdown: 60 }); // Reset countdown to 60 seconds
            const countdownInterval = setInterval(() => {
              set((state) => ({ countdown: state.countdown - 1 }));
              if (get().countdown === 0) {
                clearInterval(countdownInterval);
                set({ disableResend: false });
              }
            }, 1000);
          }
        } catch (error) {
          setError("OTP sending failed");
        } finally {
          set({ code: "" });
        }
      },
      handleResendOTP: (accessToken) => {
        get().sendOTP(accessToken);
      },
      verifyOTP: async (accessToken) => {
        set({ disableResend: false });
        const { code } = get();
        const setError = (error) => set({ error });
        setError(null);
        const apiUrl = process.env.REACT_APP_API_URL;
        try {
          const postData = {
            otp: code,
          };

          const res = await axios.patch(
            `${apiUrl}/auth/verify-email`,
            postData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          const data = res.data;

          if (data.error) {
            setError(data.error);
          }
        } catch (error) {
          setError("OTP verification failed");
        } finally {
          set({ code: "" });
          set({ disableResend: false });
        }
      },
    }),
    {
      name: "otp-form",
    }
  )
);
