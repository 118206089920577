import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [tokenStatus, setTokenStatus] = useState(null);
  const accessToken = useBackendStore((store) => store.accessToken);
  const API_URL = process.env.REACT_APP_API_URL;

  const refreshAuth = () => {
    setRefresh((prev) => !prev);
  };

  const resetAuth = () => {
    setTokenStatus(null);
  };

  useEffect(() => {
    if (!accessToken) {
      setLoading(false);
      return;
    }
    const verifyToken = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/status?token=${accessToken}`
        );
        setTokenStatus(response.data?.message);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [API_URL, accessToken, refresh]);

  return (
    <AuthContext.Provider
      value={{ loading, error, tokenStatus, refreshAuth, resetAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
