import React, { useEffect, useReducer } from "react";
import "./Styles.scss";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoExitOutline, IoNotificationsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { useSocket } from "../../contexts/socketContext";
import useProfile from "../../Hooks/useProfile";
import { toast } from "react-toastify";

export default function InputSearch() {
  const { unreadChats, unreadNotifications, resetUnreadNotifications } =
    useSocket();
  const [notificationCount, setNotificationCount] = useReducer(
    (state, action) => action,
    0
  );
  const [chatCount, setChatCount] = useReducer((state, action) => action, 0);
  const { getProfile } = useProfile();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const profile = getProfile();

  useEffect(() => {
    if (profile) {
      setNotificationCount(profile.unreadNotifications);
      setChatCount(profile?.unreadChats || 0);
    }
  }, [profile]);

  useEffect(() => {
    setNotificationCount(unreadNotifications);
    setChatCount(unreadChats);
  }, [unreadChats, unreadNotifications]);

  if (!profile) {
    toast.error("Something went wrong, please try again later");
    return null;
  }

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="inputSearch-parentDiv">
      <div className="inputSearh-icons">
        <div className="icon-container" onClick={() => navigate("/user/chat")}>
          <MdOutlineMailOutline className="icon-inputSearch msg-nav-icon" />
          {chatCount > 0 && (
            <span className="badge chat-badge">{chatCount}</span>
          )}
        </div>

        <div
          className="icon-container"
          onClick={() => {
            resetUnreadNotifications();
            navigate("/user/notifications");
          }}
        >
          <IoNotificationsOutline className="icon-inputSearch notification-icon not-nav-icon" />
          {notificationCount > 0 && (
            <span className="badge notification-badge">
              {notificationCount}
            </span>
          )}
        </div>

        <div onClick={handleLogout}>
          <span>
            <IoExitOutline className="icon-inputSearh exit-nav-icon" />
          </span>
        </div>
      </div>
    </div>
  );
}
