import { create } from "zustand";

export const useCommentStore = create(
  (set) => ({
    comment: "",
    setComment: (comment) => set({ comment }),
    error: "",
    setError: (error) => set({ error }),
  }),
  {
    name: "comment-storage",
  }
);
