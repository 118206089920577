import { useState } from "react";
import "./UserFooterNav.scss";
import { GoHome } from "react-icons/go";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useNotification } from "../../contexts/notificationsContext";
import { IoAdd, IoExitOutline, IoSearch } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoPersonOutline } from "react-icons/io5";
import useAuth from "../../Hooks/useAuth";
import Backdrop from "../backdrop/backdrop";

const UserFooterNav = () => {
  const { notifications } = useNotification;
  const { logout } = useAuth();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  const handleLogout = async () => {
    await logout();
  };

  const navigateAndTogglePopup = (path) => {
    togglePopup();
    navigate(path);
  };

  useEffect(() => {
    if (notifications?.length > 0) {
    }
  }, [notifications]);

  return (
    <section className="user-footer-nav">
      <div
        className={`user-footer-nav-settings-section-pop-up ${
          isPopupVisible ? "pop-up-visible" : "pop-up-hidden"
        }`}
      >
        <div
          className="pop-up-link"
          onClick={() => navigateAndTogglePopup("/user/profile-page")}
        >
          <div className="user-footer-nav-settings-section-pop-up-each-setting-div">
            <div className="user-footer-nav-settings-section-pop-up-each-setting">
              <div className="user-footer-nav-settings-section-pop-up-each-setting-icon-name-div">
                <p className="user-footer-nav-settings-section-pop-up-each-setting-icon">
                  <IoPersonOutline className="ito" />
                </p>
                <p className="user-footer-nav-settings-section-pop-up-each-setting-name">
                  Edit Profile
                </p>
              </div>
              <div className="user-footer-nav-settings-section-pop-up-each-setting-arrow-div">
                <p className="user-footer-nav-settings-section-pop-up-each-setting-arrow"></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="pop-up-link"
          onClick={() => navigateAndTogglePopup("/user/friends")}
        >
          <div className="user-footer-nav-settings-section-pop-up-each-setting-div">
            <div className="user-footer-nav-settings-section-pop-up-each-setting">
              <div className="user-footer-nav-settings-section-pop-up-each-setting-icon-name-div">
                <p className="user-footer-nav-settings-section-pop-up-each-setting-icon">
                  <HiOutlineUserGroup className="ito" />
                </p>
                <p className="user-footer-nav-settings-section-pop-up-each-setting-name">
                  Friends
                </p>
              </div>
              <div className="user-footer-nav-settings-section-pop-up-each-setting-arrow-div">
                <p className="user-footer-nav-settings-section-pop-up-each-setting-arrow"></p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="user-footer-nav-settings-section-pop-up-each-setting-div"
          onClick={handleLogout}
        >
          <div className="user-footer-nav-settings-section-pop-up-each-setting">
            <div className="user-footer-nav-settings-section-pop-up-each-setting-icon-name-div">
              <p className="user-footer-nav-settings-section-pop-up-each-setting-icon">
                <IoExitOutline className="ito" />
              </p>
              <p className="user-footer-nav-settings-section-pop-up-each-setting-name">
                Log Out
              </p>
            </div>
            <div className="user-footer-nav-settings-section-pop-up-each-setting-arrow-div">
              <p className="user-footer-nav-settings-section-pop-up-each-setting-arrow"></p>
            </div>
          </div>
        </div>
      </div>
      <div className="user-footer-nav-div">
        <section className="user-footer-nav-div-section">
          <div className="user-footer-nav-div-section-div" onClick={closePopup}>
            <NavLink to="/user/create-post" className="icon-not-active">
              <div className="user-footer-nav-icon-div">
                <span>
                  <IoAdd className="inputiconuserfooter" />
                </span>
              </div>
            </NavLink>
          </div>
        </section>
        <section className="user-footer-nav-div-section">
          <div className="user-footer-nav-div-section-div" onClick={closePopup}>
            <NavLink to="/user/search" className="icon-not-active">
              <div className="user-footer-nav-icon-div">
                <span className="user-footer-nav-icon">
                  <IoSearch />
                </span>
              </div>
            </NavLink>
          </div>
        </section>
        <section className="user-footer-nav-div-section">
          <div className="user-footer-nav-div-section-div" onClick={closePopup}>
            <NavLink to="/user/home" className="icon-not-active">
              <div className="user-footer-nav-icon-div">
                <span className="user-footer-nav-icon">
                  <GoHome />
                </span>
              </div>
            </NavLink>
          </div>
        </section>
        <section className="user-footer-nav-div-section">
          <div className="user-footer-nav-div-section-div" onClick={closePopup}>
            <NavLink to="/user/profile" className="icon-not-active">
              <div className="user-footer-nav-icon-div">
                <span>
                  <IoPersonOutline className="user-footer-nav-icon" />
                </span>
              </div>
            </NavLink>
          </div>
        </section>
        <section className="user-footer-nav-div-section">
          <div className="user-footer-nav-div-section-div">
            <div className="icon-not-active" onClick={togglePopup}>
              <div className="user-footer-nav-icon-div">
                <span>
                  <HiOutlineUserGroup className="inputiconuserfooter" />
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Backdrop show={isPopupVisible} onClick={togglePopup} />
    </section>
  );
};

export default UserFooterNav;
