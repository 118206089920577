import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./MediaSwiper.scss";

const MediaSwiper = ({ media }) => {
  return (
    <Swiper
      className="media-swiper"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      navigation
      pagination={{ clickable: true }}
      spaceBetween={30}
      slidesPerView={1}
    >
      {media.map((item, index) => (
        <SwiperSlide key={index}>
          {item.type === "image" ? (
            <img
              className="media-content"
              src={item.src}
              alt={item.alt || "Media"}
            />
          ) : (
            <video className="media-content" controls>
              <source src={item.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MediaSwiper;
