import { useCallback } from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";

const useUpdateProfile = () => {
  const accessToken = useBackendStore((store) => store.accessToken);
  const updateProfileInStore = useBackendStore((store) => store.updateProfile);

  const API_URL = process.env.REACT_APP_API_URL;

  const updateProfile = useCallback(
    async (updatedFields) => {
      try {
        const response = await axios.patch(
          `${API_URL}/users/me`,
          updatedFields,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        updateProfileInStore(response.data);
      } catch (error) {
        throw new Error("An error occured while updating your profile.");
      }
    },
    [accessToken, API_URL, updateProfileInStore]
  );

  const uploadImage = useCallback(
    async (image, imageType) => {
      try {
        const formData = new FormData();
        formData.append("file", image);

        const response = await axios.post(`${API_URL}/users/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        return response.data;
      } catch (error) {
        throw new Error(`An error occurred while updating your ${imageType}.`);
      }
    },
    [accessToken, API_URL]
  );

  const updateProfileImage = useCallback(
    async (profileImage) => {
      const data = await uploadImage(profileImage, "profile image");
      updateProfileInStore({ image: { profile: data.url } });
      return data.url;
    },
    [uploadImage, updateProfileInStore]
  );

  const updateCoverImage = useCallback(
    async (coverImage) => {
      const data = await uploadImage(coverImage, "cover image");
      updateProfileInStore({ image: { cover: data.url } });
      return data.url;
    },
    [uploadImage, updateProfileInStore]
  );

  return { updateProfile, updateProfileImage, updateCoverImage, uploadImage };
};

export default useUpdateProfile;
