import React from "react";
import "./logout.scss";
import useAuth from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function Logout({ toggleLogout }) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout().then(() => navigate("/login"));
  };
  return (
    <div className="Logout-parent">
      <div className="content-Logout">
        <h3>Log Out?</h3>
        <p>Are you sure you want to log out?</p>
      </div>

      <div className="div-logout-div">
        <button className="button-one-logout" onClick={toggleLogout}>
          Cancel
        </button>
        <button className="button-two-logout" onClick={handleLogout}>
          Log Out
        </button>
      </div>
    </div>
  );
}
