import { useCallback } from "react";
import axios from "axios";
import useBackendStore from "../store/auth-store";

const useComment = () => {
  const accessToken = useBackendStore((store) => store.accessToken);

  const API_URL = process.env.REACT_APP_API_URL;

  const createPostComment = useCallback(
    async (post_id, comment) => {
      try {
        const response = await axios.post(
          `${API_URL}/post-comment`,
          { post: post_id, body: comment },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  const getPostComments = useCallback(
    async (post_id, lastCreatedAt) => {
      try {
        const params = lastCreatedAt ? { lastCreatedAt } : {};
        const response = await axios.get(
          `${API_URL}/post-comment?post=${post_id}`,
          {
            params,
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    },
    [accessToken, API_URL]
  );

  const createStoryComment = useCallback(
    async (story_id, comment) => {
      try {
        const response = await axios.post(
          `${API_URL}/story-comment`,
          { story: story_id, body: comment },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  const getStoryComments = useCallback(
    async (story_id) => {
      try {
        const response = await axios.get(
          `${API_URL}/story-comment?story=${story_id}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [accessToken, API_URL]
  );

  return {
    createPostComment,
    getPostComments,
    createStoryComment,
    getStoryComments,
  };
};

export default useComment;
