import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  MainContainer,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import ChatPage from "../chatPage/ChatPage";
import useChat from "../../Hooks/useChat";
import { toast } from "react-toastify";
import { placeholder } from "../../constants/placeholder";
import useChatStore from "../../store/chat-store";
import "react-activity/dist/Squares.css";
import "./ChatFeed.scss";
import LogoSpinner from "../LogoSpinner/LogoSpinner";
import { stripHTMLTags } from "../../utils/resolve";
import { useLocation } from "react-router-dom";
import { useSocket } from "../../contexts/socketContext";
import {
  CHAT_ENTER_EVENT,
  CHAT_LEAVE_EVENT,
} from "../../constants/socketEvents";
import Badge from "../Badge/Badge"; // Import the Badge component

const ChatFeed = () => {
  const location = useLocation();
  const recId = location.state?.receiverId || null;
  const [search, setSearch] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [receiverId, setReceiverId] = useState(recId);
  const [chatId, setChatId] = useState("");
  const [profile, setProfile] = useState({});
  const [loadingChats, setLoadingChats] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sideBarVisible, setSideBarVisible] = useState(true);
  const [sidebarStyle, setSidebarStyle] = useState({});
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const { socket, unreadChats, resetUnreadChats } = useSocket();
  const { getConversations, error } = useChat();
  const bottomDivRef = useRef(null);
  const eraseMessages = useChatStore((state) => state.eraseMessages);

  const resolveLastSender = (conversation) => {
    if (conversation?.lastMsg?.from === conversation?.participant?._id) {
      return conversation?.participant?.name?.first;
    }
    return "You";
  };

  const handleBackClick = () => setSideBarVisible(true);

  const handleConversationClick = (conversation) => {
    socket.emit(CHAT_LEAVE_EVENT, chatId);
    setLoadingChats(true);
    eraseMessages();
    setReceiverId(conversation?.participant?._id);
    setChatId(conversation?._id);
    setProfile(conversation?.participant);
    setSideBarVisible(false);
    socket.emit(CHAT_ENTER_EVENT, conversation?._id);
    setLoadingChats(false);

    setConversations((prevConversations) =>
      prevConversations.map((conv) =>
        conv._id === conversation._id ? { ...conv, unreadMessages: 0 } : conv
      )
    );

    resetUnreadChats(conversation._id);
  };

  const handleSearchChange = (value) => {
    if (!value) {
      setFilteredConversations(conversations);
    }

    setSearch(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        const filteredConversations = conversations.filter((conversation) => {
          const participantFirstName =
            conversation?.participant?.name?.first?.toLowerCase();

          return participantFirstName?.includes(value.toLowerCase());
        });

        setFilteredConversations(filteredConversations);
      }, 1000)
    );
  };

  const fetchMoreConversations = useCallback(async () => {
    const data = await getConversations(lastUpdatedAt);
    if (data) {
      setConversations((prevConversations) => [...prevConversations, ...data]);
      setFilteredConversations((prevFilteredConversations) => [
        ...prevFilteredConversations,
        ...data,
      ]);
      setLastUpdatedAt(data[data.length - 1]?.updatedAt);
    }
  }, [getConversations, lastUpdatedAt]);

  const handleScroll = useCallback(() => {
    if (
      bottomDivRef.current &&
      bottomDivRef.current.parentElement.scrollTop +
        bottomDivRef.current.parentElement.clientHeight >=
        bottomDivRef.current.parentElement.scrollHeight
    ) {
      fetchMoreConversations();
    }
  }, [fetchMoreConversations]);

  useEffect(() => {
    const fetchConversations = async () => {
      const data = await getConversations();
      if (data) {
        setConversations(data);
        setFilteredConversations(data);
        setLastUpdatedAt(data[data.length - 1]?.updatedAt);
      }
      setLoading(false);
    };
    fetchConversations();
  }, [getConversations]);

  useEffect(() => {
    if (!receiverId) {
      console.warn("receiverId is null or undefined");
      return;
    }

    setSideBarVisible(false);

    if (conversations) {
      const _chatId = conversations.find(
        (c) => c?.participant?._id === receiverId
      )?._id;
      setChatId(_chatId);
    } else {
      console.warn("conversations is null or undefined");
    }

    return () => {
      setChatId(null);
    };
  }, [receiverId, conversations]);

  useEffect(() => {
    if (sideBarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "100%",
        maxWidth: "100%",
      });

      setConversationContentStyle({
        display: "flex",
      });

      setConversationAvatarStyle({
        marginRight: "1em",
      });

      setChatContainerStyle({
        display: "none",
      });
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
      setChatContainerStyle({});
    }
  }, [
    setConversationContentStyle,
    setConversationAvatarStyle,
    setSidebarStyle,
    setChatContainerStyle,
    sideBarVisible,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(
        "Oops! We couldn't load your conversations. Please try again later.",
        {
          toastId: "chat-feed-error",
        }
      );
    }
  }, [error]);

  useEffect(() => {
    const bottomDivElement = bottomDivRef.current;
    if (bottomDivElement) {
      bottomDivElement.parentElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (bottomDivElement) {
        bottomDivElement.parentElement.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      socket.emit(CHAT_LEAVE_EVENT, chatId);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [chatId, socket]);

  return (
    <MainContainer responsive style={{ zIndex: 1 }}>
      <Sidebar position="left" style={sidebarStyle}>
        <Search
          placeholder="Search..."
          value={search}
          onChange={handleSearchChange}
        />
        {!loading ? (
          <ConversationList>
            {filteredConversations.map((conversation) => (
              <Conversation
                key={conversation?._id}
                onClick={() => handleConversationClick(conversation)}
                active={chatId === conversation?._id}
                unreadCnt={
                  unreadChats?.chatId === conversation?._id
                    ? unreadChats?.unreadMessages
                    : conversation?.unreadMessages
                }
              >
                <Avatar
                  name={conversation?.name}
                  src={
                    conversation?.participant?.image?.profile ??
                    placeholder(
                      40,
                      40,
                      "png",
                      conversation?.participant?.name?.first[0]
                    )
                  }
                  style={conversationAvatarStyle}
                />
                <Conversation.Content
                  info={stripHTMLTags(conversation?.lastMsg?.message)}
                  lastSenderName={resolveLastSender(conversation)}
                  name={conversation?.participant?.name?.first}
                  style={conversationContentStyle}
                />
                {conversation?.isOfficial && <Badge text="Official" />}
              </Conversation>
            ))}
            <div ref={bottomDivRef} />
          </ConversationList>
        ) : (
          <div className="loading-container">
            <LogoSpinner className="loading-icon" />
          </div>
        )}
      </Sidebar>
      {receiverId && chatId && (
        <ChatPage
          style={chatContainerStyle}
          chatId={chatId}
          receiverId={receiverId}
          profile={profile}
          onBackClick={handleBackClick}
          loading={loadingChats}
        />
      )}
    </MainContainer>
  );
};

export default ChatFeed;
